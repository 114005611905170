export const communityKeys = {
    community: (subdomain) => ['community', subdomain],
    category: (category, limit, page) => [
        'communities',
        category,
        limit,
        page,
    ],
    twitterOAuthURL: (subdomain) => ['community:twitter:auth', subdomain],
    communityExternalAccounts: (subdomain) => ['community:twitter:verification', subdomain],
    invite: (subdomain, options) => ['community:invite', subdomain, options],
    userCommunities: (search) => !search?.length ? ['communities:user:all'] : ['communities:user:all', search],
    explore: (explore, limit) => ['communities:explore', explore.category, explore.search, explore.web2, limit],
    referralLink: (subdomain) => ['community:referral-link', subdomain],
    reportCommunity: (subdomain) => ['community:report', subdomain],
    roles: (subdomain) => ['community:roles', subdomain],
    wallet: (subdomain) => ['community:wallet', subdomain],
    failedDiscordRolesDelivery: (subdomain) => ['failedDiscordRolesDelivery', subdomain],
    onboarding: (subdomain) => ['community:onboarding', subdomain],
    listCommunitySecrets: (subdomain) => ['community:secrets', subdomain],
};
