export const questKeys = {
    board: (subdomain, filters) => !filters ? [subdomain, 'quests'] : [subdomain, 'quests', filters],
    module: (subdomain, moduleId, filters) => !filters ? [subdomain, 'quests', moduleId] : [subdomain, 'quests', moduleId, filters],
    quests: (subdomain) => [subdomain, 'quests', 'list'],
    quest: (subdomain, questId) => [subdomain, 'quests', questId],
    admin: {
        board: (subdomain, filters) => filters ? [subdomain, 'quests', 'admin', filters] : [subdomain, 'quests', 'admin'],
        quests: (subdomain) => [subdomain, 'quests', 'admin'],
        quest: (subdomain, questId) => [subdomain, 'quests', questId, 'admin'],
        withClaim: (subdomain, startDate) => [
            subdomain,
            'quests',
            'admin',
            'claim',
            startDate,
        ],
        aiSuggestions: (subdomain, useCase, description) => [
            subdomain,
            'ai',
            useCase,
            description,
        ],
    },
    results: (subdomain, questId) => [subdomain, 'quests', questId, 'results'],
    taskResults: (subdomain, questId) => [
        subdomain,
        'quests',
        questId,
        'tasks:results',
    ],
    templates: (subdomain) => [subdomain, 'templates'],
    metadata: (type, value) => ['metadata', type, value],
    questRewards: (subdomain, questId) => [subdomain, 'quests', questId, 'rewards'],
    recommendedQuests: () => ['recommendedQuests'],
    taskValidations: (subdomain, questId, taskId, sortedBy) => [subdomain, 'quests', questId, 'tasks', taskId, 'validations', 'sortedBy', sortedBy],
    voteFeed: (subdomain, questId, rewardId) => [
        subdomain,
        'quests',
        questId,
        'rewards',
        rewardId,
        'votes',
    ],
};
