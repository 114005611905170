import { jsx as _jsx } from "react/jsx-runtime";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { updateConfigs } from './services/config';
/**
 * Creates a new Query Client Instrace for React Query.
 * @param options
 * @param queryClientConfig
 */
export function createZealyQueryClient(options, queryClientConfig) {
    // Sets up the apis base URL
    updateConfigs(options);
    /**
     * Query client instance
     */
    const queryClient = new QueryClient(queryClientConfig);
    /**
     * Query Client Provider component
     */
    const ZealyQueryProvider = ({ children }) => {
        return _jsx(QueryClientProvider, { client: queryClient, children: children });
    };
    return {
        ZealyQueryProvider,
        queryClient,
    };
}
